var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-general-cash" } },
    [
      _c(
        "sig-popup",
        {
          model: {
            value: _vm.popupCashierReport,
            callback: function ($$v) {
              _vm.popupCashierReport = $$v
            },
            expression: "popupCashierReport",
          },
        },
        [_c("cashier-report-form")],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: { active: _vm.popupTransaction, title: "Movimentação" },
          on: {
            "update:active": function ($event) {
              _vm.popupTransaction = $event
            },
          },
        },
        [
          _c("transaction-delete", {
            attrs: { "cash-flow-id": _vm.tempTransaction },
            on: {
              saved: function ($event) {
                ;(_vm.popupTransaction = false),
                  (_vm.tempTransaction = null),
                  _vm.refreshGrid()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            active: _vm.popupTransactionCredit,
            title: "Editar Movimentação",
          },
          on: {
            "update:active": function ($event) {
              _vm.popupTransactionCredit = $event
            },
          },
        },
        [
          _c("transaction-edit", {
            staticStyle: { "min-height": "300px" },
            attrs: { data: _vm.localTransaction },
            on: {
              saved: function ($event) {
                ;(_vm.popupTransactionCredit = false),
                  (_vm.localTransaction = null),
                  _vm.refreshGrid()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "dialog-sig",
        {
          attrs: {
            show: _vm.popupGenerateReceiptConfirm,
            title: "ALERTA",
            icon: "warning",
            actionButtonText: "Gerar Recibo",
            actionButtonColor: "warning",
          },
          on: {
            action: function ($event) {
              return _vm.generateReceipt(
                _vm.printReceiptData,
                _vm.printReceiptNode
              )
            },
            cancel: function ($event) {
              _vm.popupGenerateReceiptConfirm = false
            },
          },
        },
        [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                "Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?"
              ),
            },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c("batch-payment", {
        attrs: {
          show: _vm.popupBatchPayment,
          firstPeriod: _vm.payload.searchQuery.firstPeriod,
          lastPeriod: _vm.payload.searchQuery.lastPeriod,
        },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupBatchPayment = false
          },
        },
      }),
      _c("credit-modal", {
        attrs: { show: _vm.popupCredit },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupCredit = false
          },
        },
      }),
      _c("debit-modal", {
        attrs: { show: _vm.popupDebit },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupDebit = false
          },
        },
      }),
      _c("chargeback-modal", {
        ref: "chargeBack",
        attrs: { show: _vm.popupChargeback },
        on: {
          saved: _vm.refreshGrid,
          cancel: function ($event) {
            _vm.popupChargeback = false
          },
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup40",
          attrs: {
            fullscreen: false,
            title: "Transferência",
            active: _vm.popupTransfer,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupTransfer = $event
            },
          },
        },
        [
          _c("form-transfer", {
            on: {
              saved: function ($event) {
                ;(_vm.popupTransfer = false), _vm.refreshGrid()
              },
            },
          }),
        ],
        1
      ),
      !_vm.showGeneralCash
        ? _c(
            "vx-card",
            { staticClass: "general-cash-filters mb-4" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-2",
                  attrs: {
                    color: "primary",
                    title: "Orientações:",
                    active: !_vm.showGeneralCash,
                    "icon-pack": "feather",
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      "Você não tem permissão para acessar o Caixa Geral."
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      "Peça para seu administrador alterar as configurações e liberar seu acesso caso necessário."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.showBalanceTotal
                ? _c(
                    "div",
                    {
                      staticClass: "vx-row",
                      staticStyle: { "margin-bottom": "-15px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                        },
                        [
                          _c("statistics-card-line", {
                            staticClass: "mb-base",
                            attrs: {
                              hideChart: "",
                              icon: "ArrowUpIcon",
                              "icon-right": "",
                              statistic: _vm.totalIn.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }),
                              statisticTitle: "Entrada Movimentações",
                              color: "success",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                        },
                        [
                          _c("statistics-card-line", {
                            staticClass: "mb-base",
                            attrs: {
                              hideChart: "",
                              icon: "ArrowDownIcon",
                              "icon-right": "",
                              statistic: _vm.totalOut.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }),
                              statisticTitle: "Saída Movimentações",
                              color: "danger",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                        },
                        [
                          _c("statistics-card-line", {
                            staticClass: "mb-base",
                            attrs: {
                              hideChart: "",
                              icon: "DollarSignIcon",
                              "icon-right": "",
                              statistic: (
                                _vm.totalIn - _vm.totalOut
                              ).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              }),
                              statisticTitle: "Saldo Movimentações",
                              color: "warning",
                              "text-color":
                                _vm.totalIn - _vm.totalOut < 0
                                  ? "danger"
                                  : "empty",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col cursor-pointer w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4",
                          on: {
                            click: function ($event) {
                              return _vm.printReport("PDF")
                            },
                          },
                        },
                        [
                          _c("statistics-card-line", {
                            staticClass: "mb-base hover:text-primary",
                            attrs: {
                              hideChart: "",
                              icon: "PrinterIcon",
                              "icon-right": "",
                              statistic: "Período",
                              statisticTitle: _vm.period,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "vx-card",
                { ref: "filterCard", staticClass: "general-cash-filters mb-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vx-row flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 sm:w-1/2 w-full" },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass: "cursor-pointer hover:text-primary",
                              attrs: {
                                "vs-custom-content": "",
                                "vs-trigger-click": "",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "text-sm opacity-75" },
                                [_vm._v("Período")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "flex cursor-pointer",
                                  attrs: { "href.prevent": "" },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "p-2 inline-flex rounded-full",
                                    class: [`text-primary`, "mb-0"],
                                    style: {
                                      background: `rgba(var(--vs-success),.15)`,
                                    },
                                    attrs: {
                                      icon: "CalendarIcon",
                                      svgClasses: "h-6 w-6",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "vs-dropdown-menu",
                                {
                                  staticClass: "w-60",
                                  staticStyle: { "z-index": "99999999999999" },
                                },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "date",
                                      label: "Data Inicial",
                                    },
                                    on: { change: _vm.debouncedFilterDate },
                                    model: {
                                      value:
                                        _vm.payload.searchQuery.firstPeriod,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payload.searchQuery,
                                          "firstPeriod",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payload.searchQuery.firstPeriod",
                                    },
                                  }),
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      type: "date",
                                      label: "Data Final",
                                    },
                                    on: { change: _vm.debouncedFilterDate },
                                    model: {
                                      value: _vm.payload.searchQuery.lastPeriod,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payload.searchQuery,
                                          "lastPeriod",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "payload.searchQuery.lastPeriod",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.periodError,
                                          expression: "periodError",
                                        },
                                      ],
                                      staticClass: "text-danger text-sm",
                                    },
                                    [
                                      _vm._v(
                                        "O período inicial deve ser anterior ao final."
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 sm:w-1/2 w-full" },
                        [
                          _c("label", { staticClass: "text-sm opacity-75" }, [
                            _vm._v("Total a Receber"),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "text-success" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.totalReceivable.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                )
                              ),
                            ]),
                          ]),
                          _c("br"),
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: "Este valor desconsidera transferências.",
                                position: "top",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "text-sm opacity-75" },
                                [_vm._v("Entrada Caixa")]
                              ),
                              _c("br"),
                              _c("span", { staticClass: "text-success" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.totalInWithoutTransfer.toLocaleString(
                                        "pt-BR",
                                        { style: "currency", currency: "BRL" }
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 sm:w-1/2 w-full" },
                        [
                          _c("label", { staticClass: "text-sm opacity-75" }, [
                            _vm._v("Total a Pagar"),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "text-warning" }, [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.totalPayable.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })
                                )
                              ),
                            ]),
                          ]),
                          _c("br"),
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: "Este valor desconsidera transferências.",
                                position: "top",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "text-sm opacity-75" },
                                [_vm._v("Saída Caixa")]
                              ),
                              _c("br"),
                              _c("span", { staticClass: "text-warning" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.totalOutWithoutTransfer.toLocaleString(
                                        "pt-BR",
                                        { style: "currency", currency: "BRL" }
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 sm:w-1/2 w-full" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-full m-icon",
                              attrs: {
                                type: "line",
                                color: "primary",
                                icon: "compare_arrows",
                                disabled: _vm.mixinDenies(
                                  "financial:general_cashier:transfer:create"
                                ),
                              },
                              on: { click: _vm.transferHandle },
                            },
                            [_vm._v("Transferência")]
                          ),
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text: "Este valor desconsidera transferências.",
                                position: "top",
                              },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "text-sm opacity-75" },
                                [_vm._v("Saldo Caixa")]
                              ),
                              _c("br"),
                              _c("span", { staticClass: "text-warning" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      (
                                        _vm.totalInWithoutTransfer -
                                        _vm.totalOutWithoutTransfer
                                      ).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 sm:w-1/2 w-full" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-full",
                              attrs: {
                                type: "line",
                                color: "primary",
                                "icon-pack": "feather",
                                icon: "icon-refresh-ccw",
                                disabled: _vm.mixinDenies(
                                  "financial:general_cashier:credit:edit"
                                ),
                              },
                              on: { click: _vm.openSidebar },
                            },
                            [_vm._v("Dar Baixa")]
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-full",
                              attrs: {
                                type: "line",
                                color: "primary",
                                "icon-pack": "feather",
                                icon: "icon-refresh-ccw",
                                disabled: _vm.mixinDenies(
                                  "financial:general_cashier:credit:edit"
                                ),
                              },
                              on: { click: _vm.batchPayment },
                            },
                            [_vm._v("Baixar Lote")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/6 sm:w-1/2 w-full" },
                        [
                          _c(
                            "vs-row",
                            { staticClass: "flex w-full" },
                            [
                              _c(
                                "vs-col",
                                { attrs: { "vs-w": "12" } },
                                [
                                  _c(
                                    "vs-button",
                                    {
                                      staticClass: "w-full",
                                      attrs: {
                                        color: "primary",
                                        "icon-pack": "feather",
                                        icon: "icon-plus-circle",
                                        disabled: _vm.mixinDenies(
                                          "financial:general_cashier:credit:create"
                                        ),
                                      },
                                      on: { click: _vm.credit },
                                    },
                                    [_vm._v("Receita")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vs-button",
                            {
                              staticClass: "w-full mt-1",
                              attrs: {
                                color: "danger",
                                "icon-pack": "feather",
                                icon: "icon-minus-circle",
                                disabled: _vm.mixinDenies(
                                  "financial:general_cashier:debit:create"
                                ),
                              },
                              on: { click: _vm.debit },
                            },
                            [_vm._v("Despesa")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "vx-card p-6" },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap items-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-grow" },
                        [
                          _c(
                            "vs-dropdown",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { "vs-trigger-click": "" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                                },
                                [
                                  _c("span", { staticClass: "mr-2" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.currentPage *
                                          _vm.paginationPageSize -
                                          (_vm.paginationPageSize - 1)
                                      ) +
                                        " - " +
                                        _vm._s(
                                          _vm.allData.total -
                                            _vm.currentPage *
                                              _vm.paginationPageSize >
                                            0
                                            ? _vm.currentPage *
                                                _vm.paginationPageSize
                                            : _vm.allData.total
                                        ) +
                                        " de " +
                                        _vm._s(_vm.allData.total)
                                    ),
                                  ]),
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronDownIcon",
                                      svgClasses: "h-4 w-4",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "vs-dropdown-menu",
                                [
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.paginationSetSize(10)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("10")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.paginationSetSize(20)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("20")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.paginationSetSize(30)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("30")])]
                                  ),
                                  _c(
                                    "vs-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.paginationSetSize(50)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("50")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "h3",
                            {
                              staticClass:
                                "inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4",
                            },
                            [_vm._v("Período: " + _vm._s(_vm.period))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "vs-button",
                        {
                          attrs: { color: "primary", type: "flat" },
                          on: {
                            click: function ($event) {
                              _vm.popupCashierReport.active = true
                            },
                          },
                        },
                        [_vm._v("Relatório")]
                      ),
                      _c("vs-input", {
                        staticClass:
                          "sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-4",
                        attrs: {
                          placeholder: "Busca rápida por responsável...",
                        },
                        on: { input: _vm.updateSearchQuery },
                        model: {
                          value: _vm.searchQuery,
                          callback: function ($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("ag-grid-vue", {
                    ref: "agGridTable",
                    staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                    attrs: {
                      components: _vm.components,
                      gridOptions: _vm.gridOptions,
                      columnDefs: _vm.columnDefs,
                      defaultColDef: _vm.defaultColDef,
                      overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                      overlayNoRowsTemplate: _vm.noRowsTemplate,
                      localeText: _vm.localeText,
                      rowSelection: "multiple",
                      colResizeDefault: "shift",
                      animateRows: true,
                      pagination: true,
                      paginationPageSize: _vm.paginationPageSize,
                      cacheBlockSize: 40,
                      suppressPaginationPanel: true,
                      enableRtl: _vm.$vs.rtl,
                      rowClass: "rowClass",
                      rowClassRules: _vm.rowClassRules,
                    },
                    on: { "grid-ready": _vm.onGridReady },
                  }),
                  _c("vs-pagination", {
                    attrs: { total: _vm.totalPages, max: 10 },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
      _c("sidebar-view", {
        attrs: {
          emailTags: [],
          openMailId: 1,
          isSidebarActive: _vm.isSidebarActive,
        },
        on: { closeSidebar: _vm.closeViewSidebar },
      }),
      _c("credit-form", { ref: "creditForm" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }