var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/5 flex justify-center" },
          [
            _c("vs-icon", {
              attrs: { icon: "warning", size: "large", color: "warning" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-4/5" },
          [
            _c(
              "vs-alert",
              {
                staticStyle: { height: "auto" },
                attrs: { active: true, color: "warning" },
              },
              [
                _c("span", [
                  _vm._v("Tem certeza que deseja excluir esta movimentação?"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full mt-4" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v("Motivo da exclusão"),
            ]),
            _c("vs-textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate.initial",
                  value: "required",
                  expression: "'required'",
                  modifiers: { initial: true },
                },
              ],
              staticClass: "mb-0",
              attrs: {
                "data-vv-as": "Motivo",
                "data-vv-scope": "chargeback",
                name: "chargebackReason",
              },
              model: {
                value: _vm.observation,
                callback: function ($$v) {
                  _vm.observation = $$v
                },
                expression: "observation",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.invalid,
                    expression: "invalid",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("chargeback.chargebackReason")))]
            ),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    color: "danger",
                    disabled: _vm.invalid || _vm.disableSave,
                  },
                  on: { click: _vm.deleteTransaction },
                },
                [_vm._v("Confirmar")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }