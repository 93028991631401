<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <div v-if="params.data && (params.data.type === 'CREDIT' || params.data.type === 'DEBIT') && params.data.transaction.situation === 'PAID'">
        <feather-icon v-if="params.data.type === 'CREDIT'" title="Recibo" icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="printReceipt" />
        <feather-icon title="Editar" icon="EditIcon" svgClasses="h-5 mr-4 w-5 hover:text-primary cursor-pointer" @click="editTransaction" />
        <feather-icon title="Estorno" icon="RotateCcwIcon" svgClasses="h-5 mr-4 w-5 hover:text-primary cursor-pointer" @click="chargeback" />
        <feather-icon title="Excluir" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="deleteTransaction" />
      </div>
      <feather-icon v-else-if="params.data && params.data.type !== 'TRANSFER' && mixinAllow('financial:general_cashier:credit:destroy')" title="Excluir definitivamente" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer"  @click="destroyTransaction" />
      <feather-icon v-else-if="params.data" title="Sem ações para esta transação!" icon="SlashIcon" svgClasses="h-5 w-5 hover:text-primary cursor-default" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    printReceipt () {
      this.params.printReceipt(this.params.data, this.params.node)// assim chamo a função de fora
    },
    chargeback () {
      if (this.mixinDenies('financial:general_cashier:credit:reverse')) {
        this.mixinNotify('Você não tem permissão para estornar', 'warning')
        return
      }
      this.params.chargeback(this.params.data)// assim chamo a função de fora
    },
    editTransaction () {
      if (this.mixinDenies('financial:general_cashier:credit:edit')) {
        this.mixinNotify('Você não tem permissão para editar', 'warning')
        return
      }
      this.params.editTransaction(this.params.data)// assim chamo a função de fora
    },
    deleteTransaction () {
      if (this.mixinDenies('financial:general_cashier:credit:destroy')) {
        this.mixinNotify('Você não tem permissão para excluir', 'warning')
        return
      }
      this.params.deleteTransaction(this.params.data)// assim chamo a função de fora
    },
    destroyTransaction () {
      if (this.mixinDenies('financial:general_cashier:credit:destroy')) {
        this.mixinNotify('Você não tem permissão para excluir', 'warning')
        return
      }
      this.params.destroyTransaction(this.params.data)// assim chamo a função de fora
    }
  }
}
</script>
