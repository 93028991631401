var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-2/3" },
          [
            _c("select-type-payment-and-account", {
              staticClass: "w-full",
              attrs: { appendToBody: true },
              model: {
                value: _vm.typePaymentAndAccount,
                callback: function ($$v) {
                  _vm.typePaymentAndAccount = $$v
                },
                expression: "typePaymentAndAccount",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/3" },
          [
            _c("select-account-plan", {
              staticClass: "w-full",
              attrs: { appendToBody: true },
              model: {
                value: _vm.accountPlan,
                callback: function ($$v) {
                  _vm.accountPlan = $$v
                },
                expression: "accountPlan",
              },
            }),
          ],
          1
        ),
      ]),
      _c("vs-divider"),
      _c(
        "div",
        { staticClass: "flex flex-row-reverse bg-grey-lighter" },
        [_c("vs-button", { on: { click: _vm.save } }, [_vm._v("Salvar")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }