var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "dialog-sig",
        {
          attrs: {
            show: _vm.popupGenerateReceiptConfirm,
            title: "ALERTA",
            icon: "warning",
            actionButtonText: "Gerar Recibo",
            actionButtonColor: "warning",
          },
          on: {
            action: function ($event) {
              return _vm.generateReceipt(
                _vm.printReceiptData,
                _vm.printReceiptNode
              )
            },
            cancel: function ($event) {
              _vm.popupGenerateReceiptConfirm = false
            },
          },
        },
        [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                "Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?"
              ),
            },
          }),
        ]
      ),
      _c("transaction-edit-modal", {
        attrs: {
          show: _vm.popupTransactionEdit,
          "transaction-prop": _vm.transaction,
        },
        on: {
          saved: function ($event) {
            return _vm.gridApi.refreshInfiniteCache()
          },
          cancel: function ($event) {
            _vm.popupTransactionEdit = false
          },
        },
      }),
      _c("chargeback-modal", {
        attrs: { show: _vm.popupChargeback },
        on: {
          saved: function ($event) {
            return _vm.gridApi.refreshInfiniteCache()
          },
          cancel: function ($event) {
            _vm.popupChargeback = false
          },
        },
      }),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            fullscreen: false,
            title: "Confirmação de Baixa",
            active: _vm.popupChangeTransactionSituation,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupChangeTransactionSituation = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mt-2 flex flex-wrap items-center justify-center",
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupChangeTransactionSituation = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mt-2 flex flex-wrap items-center justify-center",
                },
                [
                  _c(
                    "vs-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeTransactionSituation(true)
                        },
                      },
                    },
                    [_vm._v("Com Recibo")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "mt-2 flex flex-wrap items-center justify-center",
                },
                [
                  _c(
                    "vs-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.changeTransactionSituation(false)
                        },
                      },
                    },
                    [_vm._v("Sem Recibo")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-sidebar",
        {
          staticClass: "full-vs-sidebar email-view-sidebar items-no-padding",
          attrs: {
            "click-not-close": "",
            parent: "#page-general-cash",
            "hidden-background": true,
            "position-right": "",
          },
          model: {
            value: _vm.isSidebarActive,
            callback: function ($$v) {
              _vm.isSidebarActive = $$v
            },
            expression: "isSidebarActive",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mail-sidebar-content mt-12 px-0 sm:pb-6 h-full" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap items-center justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg",
                },
                [
                  _c("div", { staticClass: "flex mb-0 mt-12" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("feather-icon", {
                          staticClass: "cursor-pointer mr-4",
                          attrs: {
                            icon: _vm.$vs.rtl
                              ? "ArrowRightIcon"
                              : "ArrowLeftIcon",
                            "svg-classes": "w-6 h-6",
                          },
                          on: {
                            click: function ($event) {
                              _vm.$emit("closeSidebar")
                              _vm.gridApi = null
                            },
                          },
                        }),
                        _c("h4", [_vm._v("Voltar")]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "ml-10 mb-0 mt-12" }, [
                    _c("div", { staticClass: "flex items-baseline" }),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  tag: "component",
                  staticClass:
                    "scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6",
                  attrs: { settings: _vm.settings },
                },
                [
                  _vm.isSidebarActive
                    ? _c(
                        "div",
                        [
                          _c("hr", {
                            staticStyle: {
                              height: "1px",
                              border: "none",
                              color: "gray",
                              "background-color": "gray",
                            },
                          }),
                          _c(
                            "vx-card",
                            {
                              ref: "filterCard",
                              staticClass: "general-cash-filters mt-4 mb-4",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-row flex items-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vx-col md:w-1/6 sm:w-1/2 w-full flex",
                                    },
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass:
                                            "mr-4 cursor-pointer hover:text-primary",
                                          attrs: {
                                            "vs-custom-content": "",
                                            "vs-trigger-click": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-sm opacity-75",
                                            },
                                            [_vm._v("Período")]
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "flex cursor-pointer",
                                              attrs: { "href.prevent": "" },
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass:
                                                  "p-2 inline-flex rounded-full",
                                                class: [`text-primary`, "mb-0"],
                                                style: {
                                                  background: `rgba(var(--vs-success),.15)`,
                                                },
                                                attrs: {
                                                  icon: "CalendarIcon",
                                                  svgClasses: "h-6 w-6",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            {
                                              staticClass: "w-60",
                                              staticStyle: {
                                                "z-index": "99999999999999",
                                              },
                                            },
                                            [
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                attrs: {
                                                  type: "date",
                                                  label: "Data Inicial",
                                                },
                                                on: {
                                                  change:
                                                    _vm.debouncedFilterDate,
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload.searchQuery
                                                      .firstPeriod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.payload.searchQuery,
                                                      "firstPeriod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.searchQuery.firstPeriod",
                                                },
                                              }),
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                attrs: {
                                                  type: "date",
                                                  label: "Data Final",
                                                },
                                                on: {
                                                  change:
                                                    _vm.debouncedFilterDate,
                                                },
                                                model: {
                                                  value:
                                                    _vm.payload.searchQuery
                                                      .lastPeriod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.payload.searchQuery,
                                                      "lastPeriod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "payload.searchQuery.lastPeriod",
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.periodError,
                                                      expression: "periodError",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "text-danger text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    "O período inicial deve ser anterior ao final."
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "vs-checkbox",
                                        {
                                          staticClass: "ml-1 mt-6 flex",
                                          on: { change: _vm.filterDate },
                                          model: {
                                            value:
                                              _vm.payload.searchQuery.overdue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.payload.searchQuery,
                                                "overdue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "payload.searchQuery.overdue",
                                          },
                                        },
                                        [_vm._v("Vencidos")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vx-col md:w-2/3 sm:w-1/2 w-full",
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "vs-input--label" },
                                        [_vm._v("Fornecedor/Aluno")]
                                      ),
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate.initial",
                                            value: "required",
                                            expression: "'required'",
                                            modifiers: { initial: true },
                                          },
                                        ],
                                        attrs: {
                                          clearable: false,
                                          filterable: false,
                                          reduce: (option) => option.value,
                                          options: _vm.providerOptions,
                                          "data-vv-as": "Fornecedor",
                                          "data-vv-scope": "debit",
                                          name: "student",
                                          placeholder:
                                            "Digite CPF/CNPJ ou nome desejado...",
                                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                                        },
                                        on: {
                                          search:
                                            _vm.debouncedGetSearchProvider,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-options",
                                              fn: function ({}) {
                                                return [
                                                  _vm._v(
                                                    "\n                      Nenhum resultado encontrado.\n                    "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          590877622
                                        ),
                                        model: {
                                          value:
                                            _vm.payload.searchQuery.student_id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.payload.searchQuery,
                                              "student_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "payload.searchQuery.student_id",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vx-col md:w-1/6 sm:w-1/2 w-full",
                                    },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          staticClass: "w-full",
                                          attrs: {
                                            type: "line",
                                            color: "primary",
                                            "icon-pack": "feather",
                                            icon: "icon-refresh-ccw",
                                          },
                                          on: {
                                            click: _vm.debouncedFilterDate,
                                          },
                                        },
                                        [_vm._v("Filtrar")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-card p-6" },
                            [
                              _c(
                                "div",
                                { staticClass: "flex flex-wrap items-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex-grow" },
                                    [
                                      _c(
                                        "vs-dropdown",
                                        {
                                          staticClass: "cursor-pointer",
                                          attrs: { "vs-trigger-click": "" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.currentPage *
                                                        _vm.paginationPageSize -
                                                        (_vm.paginationPageSize -
                                                          1)
                                                    ) +
                                                      " - " +
                                                      _vm._s(
                                                        _vm.allData.total -
                                                          _vm.currentPage *
                                                            _vm.paginationPageSize >
                                                          0
                                                          ? _vm.currentPage *
                                                              _vm.paginationPageSize
                                                          : _vm.allData.total
                                                      ) +
                                                      " de " +
                                                      _vm._s(_vm.allData.total)
                                                  ),
                                                ]
                                              ),
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "ChevronDownIcon",
                                                  svgClasses: "h-4 w-4",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "vs-dropdown-menu",
                                            [
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.paginationSetPageSize(
                                                        10
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("span", [_vm._v("10")])]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.paginationSetPageSize(
                                                        20
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("span", [_vm._v("20")])]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.paginationSetPageSize(
                                                        30
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("span", [_vm._v("30")])]
                                              ),
                                              _c(
                                                "vs-dropdown-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.paginationSetPageSize(
                                                        50
                                                      )
                                                    },
                                                  },
                                                },
                                                [_c("span", [_vm._v("50")])]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4",
                                        },
                                        [
                                          _vm._v(
                                            "Período: " + _vm._s(_vm.period)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c("ag-grid-vue", {
                                ref: "agGridTable",
                                staticClass:
                                  "ag-theme-material w-100 my-4 ag-grid-table",
                                attrs: {
                                  components: _vm.components,
                                  gridOptions: _vm.gridOptions,
                                  columnDefs: _vm.columnDefs,
                                  defaultColDef: _vm.defaultColDef,
                                  overlayLoadingTemplate:
                                    _vm.overlayLoadingTemplate,
                                  overlayNoRowsTemplate: _vm.noRowsTemplate,
                                  localeText: _vm.localeText,
                                  rowSelection: "multiple",
                                  colResizeDefault: "shift",
                                  animateRows: true,
                                  pagination: true,
                                  paginationPageSize: _vm.paginationPageSize,
                                  cacheBlockSize: 40,
                                  suppressPaginationPanel: true,
                                  enableRtl: _vm.$vs.rtl,
                                  rowClass: "rowClass",
                                  rowClassRules: _vm.rowClassRules,
                                },
                                on: { "grid-ready": _vm.onGridReady },
                              }),
                              _c("vs-pagination", {
                                attrs: { total: _vm.totalPages, max: 10 },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }