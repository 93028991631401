<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">

      <div class="flex justify-between">
        <div class="flex items-center">

          <!-- IMPRIMIR SOMENTE CRÉDITO PAGO -->
          <vx-tooltip v-if="params.data && params.data.type === 'CREDIT' && params.data.situation === 'PAID'" text="Recibo" position="top" class="h-5 w-5 ml-2">
            <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="printReceipt" />
          </vx-tooltip>
          <!-- IMPRIMIR SOMENTE CRÉDITO EM ABERTO -->
          <vx-tooltip text="Impressões" v-else-if="params.data && params.data.type === 'CREDIT' && params.data.situation !== 'PAID'" position="top" class="h-5 w-5">
            <vs-dropdown vs-trigger-click class="cursor-pointer hover:text-primary">
              <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5 mr-4 cursor-pointer hover:text-primary" />
              <vs-dropdown-menu class="w-54">
                <!-- PARCELAS PENDENTES -->
                <div v-if="params.data && (params.data.situation === 'PENDING' || params.data.situation === 'EXPIRED')">
                  <!-- CRÉDITO -->
                  <vs-dropdown-item>
                    <div class="flex items-center" @click="printCarnet">
                        <feather-icon title="Recibo" icon="PrinterIcon" svgClasses="h-4 w-4 mr-2 hover:text-primary cursor-pointer" />
                        <span>Carnê</span>
                    </div>
                  </vs-dropdown-item>
                  <vs-dropdown-item>
                    <div class="flex items-center" @click="printPromissoria">
                        <feather-icon title="Recibo" icon="PrinterIcon" svgClasses="h-4 w-4 mr-2 hover:text-primary cursor-pointer" />
                        <span>Promissória</span>
                    </div>
                  </vs-dropdown-item>
                </div>

                <!-- <vs-dropdown-item>
                  <div class="flex items-center" @click="printPromissoria(params.data.id)">
                    <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                    <span>Promissória</span>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item>
                  <div class="flex items-center" @click="printCarnet(params.data.id)">
                    <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                    <span>Carnê</span>
                  </div>
                </vs-dropdown-item>
                <vs-dropdown-item v-if="params.data && params.data.situation_contract === 'CANCELED'">
                  <div class="flex items-center" @click="printWithdrawal(params.data.id)">
                    <feather-icon icon="PrinterIcon" class="inline-block mr-2" svgClasses="w-4 h-4" />
                    <span>Desistência</span>
                  </div>
                </vs-dropdown-item> -->
              </vs-dropdown-menu>
            </vs-dropdown>
          </vx-tooltip>

          <!-- ESTORNO -->
          <vx-tooltip text="Estornar" v-if="params.data && params.data.situation === 'PAID'" position="top" class="h-5 w-5 ml-2">
            <feather-icon icon="RotateCcwIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="chargeback" />
          </vx-tooltip>
          <!-- EDITAR -->
          <vx-tooltip text="Editar" v-if="params.data && params.data.situation !== 'PAID'" position="top" class="h-5 w-5 ml-2">
            <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="editTransaction" />
          </vx-tooltip>
          <!-- EXCLUIR -->
          <vx-tooltip text="Excluir" v-if="params.data && params.data.situation !== 'PAID'" position="top" class="h-5 w-5 ml-2">
            <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="confirmDeleteTransaction" />
          </vx-tooltip>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    printReceipt () {
      this.params.printReceipt(this.params.data, this.params.node)// assim chamo a função de fora
    },
    printCarnet () {
      this.params.printCarnet(this.params.node)// assim chamo a função de fora
    },
    printPromissoria () {
      this.params.printPromissoria(this.params.node)// assim chamo a função de fora
    },
    chargeback () {
      this.params.chargeback(this.params.data)// assim chamo a função de fora
    },
    confirmDeleteTransaction () {
      this.params.confirmDeleteTransaction(this.params.node)// assim chamo a função de fora
    },
    editTransaction () {
      this.params.editTransaction(this.params.node)// assim chamo a função de fora
    }
  },
  created () {
    //
  }
}
</script>
