<template>
    <div>
      <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
        <object :data="srcPdf" width="100%" style="height: 600px" />
      </vs-popup>

      <dialog-sig :show="popupGenerateReceiptConfirm" title="ALERTA" icon="warning"
        @action="generateReceipt(printReceiptData, printReceiptNode)"
        @cancel="popupGenerateReceiptConfirm = false"
        actionButtonText="Gerar Recibo" actionButtonColor="warning">
          <span v-html="'Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?'"></span>
      </dialog-sig>

      <transaction-edit-modal
        :show="popupTransactionEdit"
        :transaction-prop="transaction"
        @saved="gridApi.refreshInfiniteCache()"
        @cancel="popupTransactionEdit = false" />

      <chargeback-modal
        :show="popupChargeback"
        @saved="gridApi.refreshInfiniteCache()"
        @cancel="popupChargeback = false" />

      <!-- BAIXA RÁPIDA CONFIRMAÇÃO DE RECIBO -->
      <vs-popup class="popup50" :fullscreen="false" title="Confirmação de Baixa" :active.sync="popupChangeTransactionSituation">
        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full">
            <div class="mt-2 flex flex-wrap items-center justify-center">
              <vs-button color="secondary" @click="popupChangeTransactionSituation = false">Cancelar</vs-button>
            </div>
          </div>

          <div class="vx-col md:w-1/3 w-full">
            <div class="mt-2 flex flex-wrap items-center justify-center">
              <vs-button @click="changeTransactionSituation(true)">Com Recibo</vs-button>
            </div>
          </div>

          <div class="vx-col md:w-1/3 w-full">
            <div class="mt-2 flex flex-wrap items-center justify-center">
              <vs-button @click="changeTransactionSituation(false)">Sem Recibo</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <vs-sidebar click-not-close parent="#page-general-cash" :hidden-background="true" class="full-vs-sidebar email-view-sidebar items-no-padding" v-model="isSidebarActive" position-right>
        <div class="mail-sidebar-content mt-12 px-0 sm:pb-6 h-full">
          <div class="flex flex-wrap items-center justify-between md:px-8 px-6 sm:pb-2 sm: pt-6 d-theme-dark-bg">
            <div class="flex mb-0 mt-12">
              <div class="flex items-center">
                <feather-icon :icon="$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" @click="$emit('closeSidebar'); gridApi = null;" class="cursor-pointer mr-4" svg-classes="w-6 h-6"></feather-icon>
                <h4>Voltar</h4>
              </div>
            </div>
            <div class="ml-10 mb-0 mt-12">
              <div class="flex items-baseline">

                <!-- MOVE TO DROPDOWN -->
                <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                  <feather-icon icon="FolderIcon" svg-classes="h-6 w-6" class="ml-4"></feather-icon>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="moveTo('inbox')" v-if="currentMail.mailFolder != 'inbox'">
                      <span class="flex items-center">
                        <feather-icon icon="MailIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Inbox</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="moveTo('draft')" v-if="currentMail.mailFolder != 'draft'">
                      <span class="flex items-center">
                        <feather-icon icon="Edit2Icon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Draft</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="moveTo('spam')" v-if="currentMail.mailFolder != 'spam'">
                      <span class="flex items-center">
                        <feather-icon icon="InfoIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Spam</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="moveTo('trash')" v-if="currentMail.mailFolder != 'trash'">
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Trash</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown> -->

                <!-- ADD LABEL DROPDOWN -->
                <!-- <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                    <feather-icon icon="TagIcon" svg-classes="h-6 w-6" class="cursor-pointer ml-4"></feather-icon>
                    <vs-dropdown-menu style="z-index: 40001">
                      <vs-dropdown-item v-for="(label, index) in emailTags" :key="index" @click.stop>
                          <vs-checkbox :vs-value="label.value" v-model="currentMailLabels">{{ label.text }}</vs-checkbox>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>

                <feather-icon icon="MailIcon" svg-classes="h-6 w-6" class="cursor-pointer ml-4" @click="$emit('markUnread')"></feather-icon>
                <feather-icon v-if="currentMail.mailFolder != 'trash'" icon="TrashIcon" svg-classes="h-6 w-6" class="cursor-pointer ml-4" @click="$emit('removeMail')"></feather-icon>
                <feather-icon :icon="$vs.rtl ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'" svg-classes="h-6 w-6" class="cursor-pointer ml-4 hidden sm:inline-flex" @click="$emit('previousMail')" />
                <feather-icon :icon="$vs.rtl ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svg-classes="h-6 w-6" class="cursor-pointer ml-4 hidden sm:inline-flex" @click="$emit('nextMail')" /> -->
              </div>
            </div>
          </div>

          <component :is="scrollbarTag" class="scroll-area-mail-content md:px-8 md:pb-8 pt-4 px-6 pb-6" :settings="settings" :key="$vs.rtl">
            <div v-if="isSidebarActive">

              <hr style="height:1px;border:none;color:gray;background-color:gray;">

                <vx-card ref="filterCard" class="general-cash-filters mt-4 mb-4">
                  <div class="vx-row flex items-center">
                    <div class="vx-col md:w-1/6 sm:w-1/2 w-full flex">
                      <vs-dropdown vs-custom-content vs-trigger-click class="mr-4 cursor-pointer hover:text-primary">
                          <label class="text-sm opacity-75">Período</label>
                          <a href.prevent class="flex cursor-pointer">
                            <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 inline-flex rounded-full" :class="[`text-primary`, 'mb-0']"
                              :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                            <!-- <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="mt-1 ml-3" /> -->
                          </a>
                          <vs-dropdown-menu class="w-60" style="z-index: 99999999999999;">
                                <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.firstPeriod" class="w-full" label="Data Inicial" />
                                <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.lastPeriod" class="w-full" label="Data Final" />
                                <span class="text-danger text-sm" v-show="periodError">O período inicial deve ser anterior ao final.</span>
                          </vs-dropdown-menu>
                      </vs-dropdown>
                      <vs-checkbox class="ml-1 mt-6 flex" @change="filterDate" v-model="payload.searchQuery.overdue">Vencidos</vs-checkbox>

                    </div>
                    <div class="vx-col md:w-2/3 sm:w-1/2 w-full">
                      <label class="vs-input--label">Fornecedor/Aluno</label>
                      <v-select v-model="payload.searchQuery.student_id" @search="debouncedGetSearchProvider" :clearable="false" :filterable="false"
                        :reduce="option => option.value" :options="providerOptions" data-vv-as="Fornecedor" data-vv-scope="debit" v-validate.initial="'required'" name="student"
                        placeholder="Digite CPF/CNPJ ou nome desejado..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                        <template #no-options="{}">
                          Nenhum resultado encontrado.
                        </template>
                      </v-select>

                    </div>

                    <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
                      <vs-button @click="debouncedFilterDate" class="w-full" type="line" color="primary" icon-pack="feather" icon="icon-refresh-ccw">Filtrar</vs-button>
                    </div>
                  </div>
                </vx-card>

                <div class="vx-card p-6">

                  <div class="flex flex-wrap items-center">

                    <!-- ITEMS PER PAGE -->
                    <div class="flex-grow">
                      <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                          <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
                          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                        <vs-dropdown-menu>
                          <vs-dropdown-item @click="paginationSetPageSize(10)">
                            <span>10</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="paginationSetPageSize(20)">
                            <span>20</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="paginationSetPageSize(30)">
                            <span>30</span>
                          </vs-dropdown-item>
                          <vs-dropdown-item @click="paginationSetPageSize(50)">
                            <span>50</span>
                          </vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                      <h3 class="inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4">Período: {{ period }}</h3>
                    </div>

                    <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
                    <!-- <vs-input class="sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por responsável..." /> -->
                      <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

                      <!-- ACTION - DROPDOWN -->
                      <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

                        <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                          <span class="mr-2 leading-none">Ações</span>
                          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>

                        <vs-dropdown-menu>

                          <vs-dropdown-item>
                            <span class="flex items-center">
                              <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                              <span>Deletar</span>
                            </span>
                          </vs-dropdown-item>

                          <vs-dropdown-item>
                            <span class="flex items-center">
                              <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                              <span>Arquivo</span>
                            </span>
                          </vs-dropdown-item>

                          <vs-dropdown-item>
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                              <span>Imprimir</span>
                            </span>
                          </vs-dropdown-item>

                          <vs-dropdown-item>
                            <span class="flex items-center">
                              <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                              <span>CSV</span>
                            </span>
                          </vs-dropdown-item>

                        </vs-dropdown-menu>
                      </vs-dropdown> -->
                  </div>
                  <!-- AgGrid Table -->
                  <ag-grid-vue
                    ref="agGridTable"
                    :components="components"
                    :gridOptions="gridOptions"
                    @grid-ready="onGridReady"
                    class="ag-theme-material w-100 my-4 ag-grid-table"
                    :columnDefs="columnDefs"
                    :defaultColDef="defaultColDef"
                    :overlayLoadingTemplate="overlayLoadingTemplate"
                    :overlayNoRowsTemplate="noRowsTemplate"
                    :localeText="localeText"
                    rowSelection="multiple"
                    colResizeDefault="shift"
                    :animateRows="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :cacheBlockSize="40"
                    :suppressPaginationPanel="true"
                    :enableRtl="$vs.rtl"
                    rowClass="rowClass"
                    :rowClassRules="rowClassRules">
                  </ag-grid-vue>

                  <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

                </div>

            </div>
          </component>
        </div>
      </vs-sidebar>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'
// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererObservation from './cell-renderer/CellRendererObservation.vue'
import CellRendererSituation from './cell-renderer/CellRendererSituation.vue'

import moment from 'moment'
import _ from 'lodash'
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')
const ChargebackModal = () => import(/* webpackChunkName: "ChargebackModal" */ '@/views/apps/student/student-edit/financialTabComponents/ChargebackModal.vue')
const dialogSig = () => import('@/components/dialog/dialogSig.vue')
const TransactionEditModal = () => import(/* webpackChunkName: "TransactionEditModal" */ '@/views/apps/student/student-edit/financialTabComponents/TransactionEditModal.vue')

import moduleTransaction from '@/store/transaction/moduleTransaction.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  props: {
    emailTags: {
      type: Array,
      required: true
    },
    openMailId: {
      required: true,
      validator: prop => typeof prop === 'number' || prop === null
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      popupTransactionEdit: false,
      transaction: {
        discount: 0,
        discount_type: '%'
      },

      deleteTransactionNode: null,

      popupGenerateReceiptConfirm: false,
      printReceiptData: null,
      printReceiptNode: null,

      popupChargeback: false,

      popupChangeTransactionSituation: false,
      transactionToChangeSituation: {},

      providerOptions: [],
      searchQuery: '',
      periodError: false,
      payload: {
        offset: 0,
        limit: 40,
        searchQuery: {
          firstPeriod: '',
          lastPeriod: '',
          responsible: '',
          situation: 'PENDING',
          student_id: null,
          overdue: false
        },
        params: null,
        consultApi: true
      },
      showThread: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.50
      },

      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado neste período.',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        autoHeight: true,
        wrapText: true
      },
      rowClassRules: {
        'row-danger': (params) => {
          return params.data && params.data.situation === 'EXPIRED'
        }
      },
      localeText: null,
      columnDefs: [
        {
          headerName: 'Vencimento',
          field: 'expiration_date',
          width: 120,
          // floatingFilter:true,
          // filter: 'agDateColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['equals', 'inRange'],
          //   defaultOption: 'equals',
          //   debounceMs: 800,
          // comparator: (filterLocalDateAtMidnight, cellValue) => {
          //   const dateAsString = cellValue
          //   if (dateAsString === null) return -1
          //   const dateParts = dateAsString.split('/')
          //   const cellDate = new Date(
          //     Number(dateParts[2]),
          //     Number(dateParts[1]) - 1,
          //     Number(dateParts[0])
          //   )

          //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          //     return 0
          //   }

          //   if (cellDate < filterLocalDateAtMidnight) {
          //     return -1
          //   }

          //   if (cellDate > filterLocalDateAtMidnight) {
          //     return 1
          //   }
          // },
          //   browserDatePicker: true,
          //   minValidYear: 2000
          // },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Descrição',
          field: 'observation',
          minWidth: 400,
          cellClass: 'my-class',
          // valueFormatter: params => {
          //   if (params.data) {
          //     const services = (params.data.transaction && params.data.transaction.servicePackages) ? params.data.transaction.servicePackages.map(e => ` ${e.name} ${e.category_cnh || ''}`).flat() : ''
          //     if (params.data.type !== 'CHARGEBACK' && params.data.transaction) {
          //       return (params.data.student ? `${params.data.student.name} | ` : '') + (params.data.transaction.observation || 'Nenhuma descrição.') + services
          //     } else if (params.data.type === 'CHARGEBACK') {
          //       return `ESTORNO ${(params.data.transaction.type === 'DEBIT' ? ' DE DÉBITO | ' : ' DE CRÉDITO | ')} ${(params.data.student ? `${params.data.student.name} | ` : '')} ${(params.data.transaction.observation || 'Nenhuma descrição.')} ${services}`
          //     }
          //   }
          // },
          sortable: false,
          cellRendererFramework: 'CellRendererObservation'

        // data.transaction && data.type !== 'CHARGEBACK' ?
        //     (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'NENHUMA DESCRIÇÃO.') :
        // data.type === 'CHARGEBACK' ?
        //     'ESTORNO ' + (data.transaction.type == 'DEBIT' ? ' DE DÉBITO | ': ' DE CRÉDITO | ' ) + (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'Nenhuma descrição.') :
        //     ''


          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   numberParser: (text) => {
          //     return text === null ? null : text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
          //       (regex, a1, a2, a3, a4) => `${a1}.${a2}.${a3}-${a4}`)
          //   },
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Aluno/Fornecedor',
          field: 'student.name',
          width: 200,
          // valueFormatter: params => {
          //   if (params.value) {
          //     if (params.value === 'TRANSFER') return 'Transferência'
          //     else if (params.value === 'CHARGEBACK') return 'Estorno'
          //     else if (params.value) return params.data.type_payment.type_payment
          //   }
          // },
          sortable: true
          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
        },
        {
          headerName: 'Valor',
          field: 'value_parcel',
          width: 115,
          valueFormatter: params => {
            return params.value ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Baixar',
          field: 'situation',
          width: 100,
          cellRendererFramework: 'CellRendererSituation',
          sortable: false,
          onCellClicked: (event) => { this.handleChangeTransactionSituation(event.node) }

          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
        },
        {
          headerName: 'Ações',
          field: 'actions',
          width: 110,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            printReceipt: this.printReceipt, // usado para passar parametros para dentro o componente renderizado no ag grid
            chargeback: this.chargeback, // usado para passar parametros para dentro o componente renderizado no ag grid
            confirmDeleteTransaction: this.confirmDeleteTransaction, // usado para passar parametros para dentro o componente renderizado no ag grid
            editTransaction: this.editTransaction, // usado para passar parametros para dentro o componente renderizado no ag grid
            printCarnet: this.printCarnet, // usado para passar parametros para dentro o componente renderizado no ag grid
            printPromissoria: this.printPromissoria // usado para passar parametros para dentro o componente renderizado no ag grid
          }
        }
      ],

      components: {
        CellRendererDateTime,
        CellRendererActions,
        CellRendererObservation,
        CellRendererSituation
      }

    }
  },
  watch: {
    isSidebarActive (value) {
      if (!value) {
        this.$emit('closeSidebar')
        setTimeout(() => {
          this.showThread = false
        }, 500)
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    period () {
      if (this.payload.searchQuery.firstPeriod && this.payload.searchQuery.lastPeriod) {
        return `${moment(this.payload.searchQuery.firstPeriod).format('DD/MM/YYYY')} até ${moment(this.payload.searchQuery.lastPeriod).format('DD/MM/YYYY')}`
      }
      return `${moment().format('DD/MM/YYYY')} até ${moment().format('DD/MM/YYYY')}`
    },
    allData () {
      const data = this.$store.state.transaction.transactionsGrid// ? this.$store.state.generalCash.generalTransactions : null
      return JSON.parse(JSON.stringify(data))
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    printCarnet (node) {
      this.$vs.loading()
      this.$http.get(`${process.env.VUE_APP_API_URL}/document/pdf/carnet/${node.data.id}`)
        .then(function (response) {
          this.showPdfTitle = 'Carnê'
          this.srcPdf = `data:application/pdf;base64,${response.data}`
          this.popupShowPdf = true
          this.$vs.loading.close()
        }.bind(this))
        .catch(function (error) {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }.bind(this))
    },
    printPromissoria (node) {
      this.$vs.loading()
      this.$http.get(`${process.env.VUE_APP_API_URL}/document/pdf/promissoria/${node.data.id}`)
        .then(function (response) {
          this.showPdfTitle = 'Promissória'
          this.srcPdf = `data:application/pdf;base64,${response.data}`
          this.popupShowPdf = true
          this.$vs.loading.close()
        }.bind(this))
        .catch(function (error) {
          this.$vs.loading.close()

          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning',
              position: 'top-center'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }.bind(this))
    },
    editTransaction (node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_batch_edit') // editar parcelas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      const transaction = JSON.parse(JSON.stringify(node.data))
      transaction.discount = 0
      transaction.discount_type = '%'
      transaction.applyInterest = true
      transaction.situation = transaction.situation === 'PAID'
      transaction.futureDate = false
      this.transaction = transaction
      this.popupTransactionEdit = true
    },
    confirmDeleteTransaction (node) {

      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_student_batch_edit') // editar parcelas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('delete'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.deleteTransactionNode = node
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmar Exclusão',
        text: 'Tem certeza que deseja DELETAR esta movimentação?!',
        accept: this.deleteTransaction,
        acceptText: 'Delete'
      })
    },
    deleteTransaction () {
      this.$vs.loading()
      this.$store.dispatch('transaction/delete', this.deleteTransactionNode.data.id)
        .then(() => {
          this.$vs.loading.close()
          this.payload.consultApi = false //uma consulta desnecessária a menos à API
          this.gridApi.refreshInfiniteCache()
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados excluídos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
          this.deleteTransactionNode = {}
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.deleteTransactionNode = {}
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },
    printReceipt (data, node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_recibo')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (data.receipts.length) {
        //possui recibo, imprime 2ª via
        this.generateReceipt(data, node)
      } else {
        //não possui recibo, devo perguntar e se for o caso cria recibo
        this.popupGenerateReceiptConfirm = true
        this.printReceiptData = data
        this.printReceiptNode = node
      }
    },
    generateReceipt (data, node) {
      this.$vs.loading()
      this.popupGenerateReceiptConfirm = false
      this.$http.get(`${process.env.VUE_APP_API_URL}/document/pdf/receipt/${data.id}`)
        .then(function (response) {
          this.showPdfTitle = 'Recibo'
          this.srcPdf = `data:application/pdf;base64,${response.data.pdf}`
          this.popupShowPdf = true

          /**
           * ALTERANDO GRID SEM REFRESH NAS LINHAS ABAIXO
           */
          Object.assign(data, response.data.transaction) // Precisa ser assim em função da reatividade
          const rowNode = this.gridApi.getRowNode(node.id)
          rowNode.setData(data)

          // rowNode.setDataValue('type', 'CHARGEBACK')
          if (!data.receipts.length) this.$store.commit('transaction/UPDATE', data)

          this.$vs.loading.close()
        }.bind(this))
        .catch(function () {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        }.bind(this))
    },
    chargeback (data) {
      this.$store.commit('transaction/SET', data)
      this.popupChargeback = true
    },
    handleChangeTransactionSituation (node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_batch_edit') // baixar parcelas
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      if (node.data.situation !== 'PAID' && node.data.type === 'CREDIT') {
        this.transactionToChangeSituation = node
        this.popupChangeTransactionSituation = true
      } else {
        this.transactionToChangeSituation = node
        this.changeTransactionSituation(false)
      }
    },
    async changeTransactionSituation (receipt) {
      try {
        await this.$store.dispatch('transaction/experssPayment', this.transactionToChangeSituation.data)
        this.payload.consultApi = false

        this.popupChangeTransactionSituation = false

        if (receipt) {
          this.generateReceipt(this.transactionToChangeSituation.data, this.transactionToChangeSituation)
        }
        this.gridApi.refreshInfiniteCache()

        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Situação Alterada.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
      } catch (error) {
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
    },
    getSearchResult (searchVal) {
      this.payload.searchQuery.responsible = searchVal
      // this.saldoAnterior = 0
      this.gridApi.setDatasource(this.dataSource)
    },
    filterDate () {
      if (moment(this.payload.searchQuery.firstPeriod).year() > 1000 && moment(this.payload.searchQuery.lastPeriod).year() > 1000) {
        const difDays = moment(this.payload.searchQuery.lastPeriod).diff(moment(this.payload.searchQuery.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial deve ser anterior ao final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        // this.saldoAnterior = 0
        this.gridApi.setDatasource(this.dataSource)
      } else if (!this.payload.searchQuery.firstPeriod && !this.payload.searchQuery.lastPeriod) {
        this.periodError = false
        this.gridApi.setDatasource(this.dataSource)
      }
    },

    fetchproviderOptions (search, loading) {
      if (search.length) {
        loading(true)
        // busco alunos e fornecedores
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: null })
          .then(function (response) {
            this.providerOptions = []
            for (const k in response.data) {
              this.providerOptions.push({
                value: response.data[k].id,
                label: `${response.data[k].name} ${response.data[k].cpf || ''}`,
                account_plan_id: response.data[k].account_plan_id
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })

      } else {
        loading(false)
      }
    },

    onGridReady (params) {
      this.gridApi = params.api
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.setGrid('allData')
      this.gridApi.showLoadingOverlay()
      const data = await this.$store.dispatch('transaction/fetchGrid', this.payload).catch(err => { console.error(err) })
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return JSON.parse(JSON.stringify(data))
    }
    // moveTo (to) {
    //   this.$emit('closeSidebar')
    //   this.$emit('moveTo', to)
    // }
  },
  components: {
    VuePerfectScrollbar,
    AgGridVue,
    CellRendererDateTime,
    CellRendererActions,
    CellRendererObservation,
    CellRendererSituation,
    vSelect,
    permissionPassword,
    ChargebackModal,
    dialogSig,
    TransactionEditModal
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${ String(Number(header.style.transform.slice(11, -3)) + 9) }px`
    }
  },
  created () {
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.debouncedFilterDate = _.debounce(this.filterDate, 600)
    this.debouncedGetSearchProvider = _.debounce(this.fetchproviderOptions, 600)

    if (!moduleTransaction.isRegistered) {
      this.$store.registerModule('transaction', moduleTransaction)
      moduleTransaction.isRegistered = true
    }
  }
}
</script>

