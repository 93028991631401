<template>
  <div>
    <div class="vx-row">
      <div class="vx-col md:w-2/3">
          <select-type-payment-and-account v-model="typePaymentAndAccount" class="w-full" :appendToBody="true" />
      </div>
       <div class="vx-col md:w-1/3">
          <select-account-plan v-model="accountPlan" class="w-full" :appendToBody="true" />
      </div>
    </div>
    <vs-divider></vs-divider>
    <div class="flex flex-row-reverse bg-grey-lighter">
      <vs-button @click="save"
          >Salvar</vs-button>
      </div>
  </div>
</template>

<script>
// Store Module
import SelectTypePaymentAndAccount from '@/components/financial/typePayment/SelectTypePaymentAndAccount.vue'
import SelectAccountPlan from '@/components/financial/accountPlan/SelectAccountPlan.vue'

export default {
  components: {
    SelectTypePaymentAndAccount,
    SelectAccountPlan
  },
  props: {
    data: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      typePaymentAndAccount:  { typePayment: null, account: null },
      accountPlan: null,
      situation: 'PAID',
      account: null,
      valueParcel: 0
    }
  },
  watch: {
    data (transaction) {
      this.setLocalData(transaction)
    }
  },
  methods: {
    setLocalData (transaction) {
      if (!transaction) return
      // tipo de pagamento
      this.typePaymentAndAccount = { typePayment: null, account: null }
      if (transaction.typePayment && transaction.typePayment.id) this.typePaymentAndAccount.typePayment = transaction.typePayment
      else if (transaction.type_payment && transaction.type_payment.id) this.typePaymentAndAccount.typePayment = this.data.type_payment
      else if (transaction.type_payment_id) this.typePaymentAndAccount.typePayment = { id: this.data.type_payment_id }

      // conta
      if (transaction.account && transaction.account.id) this.typePaymentAndAccount.account = transaction.account
      else if (transaction.account_id) this.typePaymentAndAccount.account = { id: transaction.account_id }

      // plano de conta
      if (transaction.accountPlan && transaction.accountPlan.id) this.accountPlan = transaction.accountPlan
      else if (transaction.account_plan && transaction.account_plan.id) this.accountPlan = transaction.account_plan
      else if (transaction.account_plan_id) this.accountPlan = { id: transaction.account_plan_id }

    },
    save () {
      if (!this.validate()) return
      const transaction = {
        id: this.data.id,
        type_payment_id: this.typePaymentAndAccount.typePayment.id,
        account_id: this.typePaymentAndAccount.account.id,
        account_plan_id: this.accountPlan.id
      }
      this.$vs.loading()
      this.$store.dispatch('generalCash/update', transaction)
        .then(() => {
          this.mixinNotify('Operação executada com sucesso')
          this.$emit('saved')
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    },
    validate () {
      try {
        const transaction = {
          id: this.data.id,
          type_payment_id: this.typePaymentAndAccount.typePayment.id,
          account_id: this.typePaymentAndAccount.account.id,
          account_plan_id: this.accountPlan.id
        }
        return true
      } catch (e) {
        this.mixinNotify('Preencha todos os campos', 'warning')
      }
    }
  },
  mounted () {
    this.setLocalData(this.data)
  }
}
</script>
