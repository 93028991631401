<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/5 flex justify-center">
        <vs-icon icon="warning" size="large" color="warning"></vs-icon>
      </div>
      <div class="vx-col w-4/5">
        <vs-alert class="" :active="true" color="warning" style="height: auto">
          <span>Tem certeza que deseja excluir esta movimentação?</span>
        </vs-alert>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mt-4">
        <label class="vs-input--label">Motivo da exclusão</label>
        <vs-textarea
          class="mb-0"
          v-model="observation"
          data-vv-as="Motivo"
          data-vv-scope="chargeback"
          v-validate.initial="'required'"
          name="chargebackReason"
        />
        <span
          class="text-danger text-sm"
          v-show="invalid"
          >{{ errors.first("chargeback.chargebackReason") }}</span
        >
      </div>
    </div>
    <vs-divider />
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button
            class="ml-4"
            @click="deleteTransaction"
            color="danger"
            :disabled="invalid || disableSave"
            >Confirmar</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    cashFlowId:{}
  },
  data () {
    return {
      observation: null,
      disableSave: false
    }
  },
  computed: {
    invalid () {
      return ''
    }
  },
  methods: {
    deleteTransaction () {
      this.disableSave = true
      setTimeout(() => { this.disableSave = false }, 2000) //TRAVA O BOTÃO SALVAR
      // excluir fluxo de caixa
      if (this.cashFlowId) {
        this.executeAction({
          dispatch: 'generalCash/destroy',
          id: this.cashFlowId
        })
      }
    },
    // salva e envia para o servidor
    executeAction ({dispatch, id}) {
      this.$vs.loading()
      this.$store.dispatch(dispatch, {observation: this.observation, id})
        .then(() => {
          this.$emit('saved')
          this.mixinNotify('Operação executada com sucesso')
        })
        .catch(this.mixinCatch)
        .finally(this.mixinFinally)
    }
  }
}
</script>
