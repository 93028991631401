var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data
    ? _c(
        "div",
        { staticClass: "flex items-start" },
        [
          _vm.type === "CREDIT"
            ? _c("feather-icon", {
                staticClass: "ml-1 mr-1 mt-2 text-success",
                attrs: {
                  title: "Entrada",
                  icon: "ArrowUpIcon",
                  svgClasses: "h-4 w-4",
                },
              })
            : _c("feather-icon", {
                staticClass: "ml-1 mr-1 mt-2 text-danger",
                attrs: {
                  title: "Saída",
                  icon: "ArrowDownIcon",
                  svgClasses: "h-4 w-4",
                },
              }),
          _vm._v("\n  " + _vm._s(_vm.observation) + "\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }