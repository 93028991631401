<template>
  <div v-if="params.data && situation === 'ABERTO'" class="flex items-center">
      <vx-tooltip text="Baixa Rápida" position="top" class="flex items-center">
        <feather-icon icon="MenuIcon" svgClasses="h-5 w-5 mr-1 cursor-pointer hover:text-primary" />
        <span class="cursor-pointer hover:text-primary">BAIXAR</span>
      </vx-tooltip>
  </div>
  <div v-else-if="params.data" class="flex items-center">
    {{ situation }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererSituation',
  computed: {
    situation () {
      return this.params.value === 'PAID' ? 'PAGO' : 'ABERTO'
    }
  }
}
</script>
