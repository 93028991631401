<template>

  <div id="page-general-cash">
     <sig-popup v-model="popupCashierReport">
        <cashier-report-form />
    </sig-popup>
    <!-- excluir transação -->
     <vs-popup :active.sync="popupTransaction" title="Movimentação" class="popup50" >
        <transaction-delete @saved="popupTransaction = false, tempTransaction = null, refreshGrid()" :cash-flow-id="tempTransaction" />
      </vs-popup>
      <!-- editar transação -->
      <vs-popup :active.sync="popupTransactionCredit" title="Editar Movimentação">
        <transaction-edit :data="localTransaction" style="min-height: 300px" @saved="popupTransactionCredit = false, localTransaction = null, refreshGrid()" />
      </vs-popup>
    <dialog-sig :show="popupGenerateReceiptConfirm" title="ALERTA" icon="warning"
      @action="generateReceipt(printReceiptData, printReceiptNode)"
      @cancel="popupGenerateReceiptConfirm = false"
      actionButtonText="Gerar Recibo" actionButtonColor="warning">
        <span v-html="'Um novo recibo será criado! <br> Tem certeza que deseja emitir este recibo?'"></span>
    </dialog-sig>

    <vs-popup class="popup80 noOverflow" :fullscreen="false" :title="showPdfTitle" :active.sync="popupShowPdf">
      <object :data="srcPdf" width="100%" style="height: 600px" />
    </vs-popup>

    <batch-payment
      :show="popupBatchPayment"
      :firstPeriod="payload.searchQuery.firstPeriod"
      :lastPeriod="payload.searchQuery.lastPeriod"
      @saved="refreshGrid"
      @cancel="popupBatchPayment = false"></batch-payment>

    <credit-modal
      :show="popupCredit"
      @saved="refreshGrid"
      @cancel="popupCredit = false"></credit-modal>

    <debit-modal
      :show="popupDebit"
      @saved="refreshGrid"
      @cancel="popupDebit = false"></debit-modal>

    <chargeback-modal
      :show="popupChargeback"
      @saved="refreshGrid"
      ref="chargeBack"
      @cancel="popupChargeback = false"></chargeback-modal>

    <!-- TRANSFERÊNCIAS -->
    <vs-popup class="popup40" :fullscreen="false" title="Transferência" :active.sync="popupTransfer">
      <form-transfer @saved="popupTransfer = false, refreshGrid()" />
      <!-- <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">De</label>
          <v-select v-model="account_from" :reduce="option => option.value" :clearable="false"
            data-vv-as="De" data-vv-scope="transfer" v-validate.initial="'required'" name="account_from"
            :options="accountsFromOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">Para</label>
          <v-select v-model="account_to" :reduce="option => option.value" :clearable="false"
            data-vv-as="Para" data-vv-scope="transfer" v-validate.initial="'required'" name="account_to"
            :options="accountsToOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="vs-input--label">Valor</label>
          <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
            v-model="transfer_value"
            :currency="{prefix: 'R$ '}"
            :valueAsInteger="false"
            :distractionFree="false"
            :precision="2"
            :autoDecimalMode="true"
            :valueRange="{ min: 0 }"
            :allowNegative="false" />
        </div>
      </div>

      <vs-divider />
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
            <vs-button color="secondary" class="ml-auto" @click="popupTransfer = false">Cancelar</vs-button>
            <vs-button class="ml-4" color="danger" @click="transfer">Transferir</vs-button>
          </div>
        </div>
      </div> -->

    </vs-popup>
      <vx-card v-if="!showGeneralCash" class="general-cash-filters mb-4">
        <vs-alert class="mt-2" color="primary" title="Orientações:" :active="!showGeneralCash" icon-pack="feather">
          <p>Você não tem permissão para acessar o Caixa Geral.</p>
          <p>Peça para seu administrador alterar as configurações e liberar seu acesso caso necessário.</p>
        </vs-alert>
      </vx-card>

      <div v-else>
        <!-- ROW 2 -->
        <div v-if="showBalanceTotal" class="vx-row" style="margin-bottom: -15px">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ArrowUpIcon"
                icon-right
                :statistic="totalIn.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                statisticTitle="Entrada Movimentações"
                color="success" />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ArrowDownIcon"
                icon-right
                :statistic="totalOut.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                statisticTitle="Saída Movimentações"
                color="danger" />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="DollarSignIcon"
                icon-right
                :statistic="(totalIn - totalOut).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })"
                statisticTitle="Saldo Movimentações"
                color="warning"
                :text-color="(totalIn - totalOut) < 0 ? 'danger' : 'empty'" />
          </div>

          <div class="vx-col cursor-pointer w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="printReport('PDF')">
              <statistics-card-line
                hideChart
                class="mb-base hover:text-primary"
                icon="PrinterIcon"
                icon-right
                statistic="Período"
                :statisticTitle="period" />
          </div>
        </div>

        <vx-card ref="filterCard" class="general-cash-filters mb-4">
          <div class="vx-row flex-wrap items-center justify-end">
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer hover:text-primary">
                  <label class="text-sm opacity-75">Período</label>
                  <a href.prevent class="flex cursor-pointer">
                    <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="p-2 inline-flex rounded-full" :class="[`text-primary`, 'mb-0']"
                      :style="{background: `rgba(var(--vs-success),.15)`}"></feather-icon>
                    <!-- <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="mt-1 ml-3" /> -->
                  </a>
                  <vs-dropdown-menu class="w-60" style="z-index: 99999999999999;">
                        <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.firstPeriod" class="w-full" label="Data Inicial" />
                        <vs-input @change="debouncedFilterDate" type="date" v-model="payload.searchQuery.lastPeriod" class="w-full" label="Data Final" />
                        <span class="text-danger text-sm" v-show="periodError">O período inicial deve ser anterior ao final.</span>
                  </vs-dropdown-menu>
              </vs-dropdown>
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Total a Receber</label> <br>
              <span class="text-success">
                <strong>{{totalReceivable.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
              </span> <br>

              <vx-tooltip text="Este valor desconsidera transferências." position="top">
                <label class="text-sm opacity-75">Entrada Caixa</label> <br>
                <span class="text-success">
                  <strong>{{totalInWithoutTransfer.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
                </span>
              </vx-tooltip>

            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <label class="text-sm opacity-75">Total a Pagar</label> <br>
              <span class="text-warning">
                <strong>{{totalPayable.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
              </span> <br>

              <vx-tooltip text="Este valor desconsidera transferências." position="top">
                <label class="text-sm opacity-75">Saída Caixa</label> <br>
                <span class="text-warning">
                  <strong>{{totalOutWithoutTransfer.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
                </span>
              </vx-tooltip>
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-button @click="transferHandle" class="w-full m-icon" type="line" color="primary" icon="compare_arrows" :disabled="mixinDenies('financial:general_cashier:transfer:create')">Transferência</vs-button>
              <vx-tooltip text="Este valor desconsidera transferências." position="top">
                <label class="text-sm opacity-75">Saldo Caixa</label> <br>
                <span class="text-warning">
                  <strong>{{(totalInWithoutTransfer - totalOutWithoutTransfer).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</strong>
                </span>
              </vx-tooltip>
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
              <vs-button @click="openSidebar" class="w-full" type="line" color="primary" icon-pack="feather" icon="icon-refresh-ccw" :disabled="mixinDenies('financial:general_cashier:credit:edit')">Dar Baixa</vs-button>
              <vs-button @click="batchPayment" class="w-full" type="line" color="primary" icon-pack="feather" icon="icon-refresh-ccw" :disabled="mixinDenies('financial:general_cashier:credit:edit')">Baixar Lote</vs-button>
            </div>
            <div class="vx-col md:w-1/6 sm:w-1/2 w-full">
             <vs-row class="flex w-full">
                <!-- <vs-col vs-w="2" vs-align="flex-start">
                  <vs-button
                    @click="$refs.creditForm.popup = true"
                    :disabled="cashier.status === 'CLOSED'"
                    type="border"
                    icon-pack="feather"
                    icon="icon-plus-circle"
                    class="w-full"
                  />
                </vs-col> -->
                <vs-col vs-w="12">
                <vs-button @click="credit" class="w-full" color="primary" icon-pack="feather" icon="icon-plus-circle" :disabled="mixinDenies('financial:general_cashier:credit:create')">Receita</vs-button>
                </vs-col>
              </vs-row>

              <vs-button @click="debit" class="w-full mt-1" color="danger" icon-pack="feather" icon="icon-minus-circle" :disabled="mixinDenies('financial:general_cashier:debit:create')">Despesa</vs-button>
            </div>
          </div>
        </vx-card>

        <div class="vx-card p-6">

          <div class="flex flex-wrap items-center">

            <!-- ITEMS PER PAGE -->
            <div class="flex-grow">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                  <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ allData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : allData.total }} de {{ allData.total }}</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="paginationSetSize(10)">
                    <span>10</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="paginationSetSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="paginationSetSize(30)">
                    <span>30</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="paginationSetSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
              <h3 class="inline-flex sm:ml-6 ml-2 sm:w-auto w-full sm:order-normal order-2 sm:mt-0 mt-4">Período: {{ period }}</h3>
            </div>
              <vs-button
              color="primary"
              @click="popupCashierReport.active = true"
              type="flat"
              >Relatório</vs-button
            >
            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <vs-input class="sm:mr-4 mr-0 sm:w-1/3 w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por responsável..." />
              <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

              <!-- ACTION - DROPDOWN -->
              <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

                <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                  <span class="mr-2 leading-none">Ações</span>
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu>

                  <vs-dropdown-item>
                    <span class="flex items-center">
                      <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>Deletar</span>
                    </span>
                  </vs-dropdown-item>

                  <vs-dropdown-item>
                    <span class="flex items-center">
                      <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>Arquivo</span>
                    </span>
                  </vs-dropdown-item>

                  <vs-dropdown-item>
                    <span class="flex items-center">
                      <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>Imprimir</span>
                    </span>
                  </vs-dropdown-item>

                  <vs-dropdown-item>
                    <span class="flex items-center">
                      <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                      <span>CSV</span>
                    </span>
                  </vs-dropdown-item>

                </vs-dropdown-menu>
              </vs-dropdown> -->
          </div>
          <!-- AgGrid Table -->
          <ag-grid-vue
            ref="agGridTable"
            :components="components"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :localeText="localeText"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :cacheBlockSize="40"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
            rowClass="rowClass"
            :rowClassRules="rowClassRules">
          </ag-grid-vue>

          <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

        </div>
      </div>
        <!-- EMAIL VIEW SIDEBAR -->
        <sidebar-view
            :emailTags       = "[]"
            :openMailId      = "1"
            :isSidebarActive = "isSidebarActive"
            @closeSidebar    = "closeViewSidebar">
        </sidebar-view>
  <credit-form ref="creditForm"/>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import CashierReportForm from '@/components/cashier/CashierReportForm'
import SigPopup from '@/components/sig/SigPopup'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import _ from 'lodash'
import { localePt_Br } from '@/assets/agGridTranslate/locale.pt_Br'
import SidebarView           from './baixa/SidebarView.vue'
import CreditForm from '@/components/cashier/credit/CreditForm'
import TransactionDelete from '@/components/cashier/transaction/TransactionDelete'
import TransactionEdit from '@/components/cashier/transaction/TransactionEdit'
import FormTransfer from '@/components/financial/transfer/FormTransfer.vue'
// Cell Renderer
import CellRendererDateTime from './cell-renderer/CellRendererDateTime.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

// import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
const StatisticsCardLine = () => import(/* webpackChunkName: "StatisticsCardLine" */ '@/components/statistics-cards/StatisticsCardLine.vue')
const BatchPayment = () => import(/* webpackChunkName: "BatchPayment" */ './BatchPayment.vue')
const CreditModal = () => import(/* webpackChunkName: "CreditModal" */ './CreditModal.vue')
const DebitModal = () => import(/* webpackChunkName: "DebitModal" */ './DebitModal.vue')
const ChargebackModal = () => import(/* webpackChunkName: "ChargebackModal" */ './ChargebackModal.vue')
const dialogSig = () => import('@/components/dialog/dialogSig.vue')
const vSelect = () => import(/* webpackChunkName: "vSelect" */ 'vue-select')
import moment from 'moment'
import axios from '@/axios.js'
import { CurrencyInput } from 'vue-currency-input'

// Store Module
import moduleGeneralCash from '@/store/general-cash/moduleGeneralCash.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleMyCashier from '@/store/my-cashier/moduleMyCashier.js'

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    SidebarView,
    CashierReportForm,
    AgGridVue,
    SigPopup,
    FormTransfer,
    TransactionDelete,
    TransactionEdit,

    // Cell Renderer
    CellRendererDateTime,
    CellRendererActions,

    StatisticsCardLine,
    BatchPayment,
    CreditModal,
    CreditForm,
    DebitModal,
    ChargebackModal,
    dialogSig,
    vSelect,
    CurrencyInput

  },
  data () {
    return {
      localTransaction: null, // usada para editar transacao
      tempTransaction: {},
      popupTransaction: false,
      popupTransactionCredit: false,
      isSidebarActive      : false,
      popupCashierReport: {
        active: false,
        title: 'Relatório de Caixa'
      },
      showGeneralCash: true,
      showBalanceTotal: true,

      popupTransfer: false,
      transfer_value: 0,
      account_to: null,
      account_from: null,
      accountsFromOptions: [],
      accountsToOptions: [],

      popupGenerateReceiptConfirm: false,
      printReceiptData: null,
      printReceiptNode: null,

      showPdfTitle: 'PDF',
      srcPdf: '',
      popupShowPdf: false,

      popupBatchPayment: false,
      popupCredit: false,
      popupDebit: false,
      popupChargeback: false,
      totalIn: 0,
      totalOut: 0,
      totalInWithoutTransfer: 0,
      totalOutWithoutTransfer: 0,
      totalReceivable: 0,
      totalPayable: 0,
      periodError: false,
      saldoAnterior: 0,
      rowClassRules: {
        'row-danger': (params) => {
          return params.data && params.data.direction === 'OUT'
        }
      },
      localeText: null,
      // Filter Options
      // roleFilter: { label: 'Todos', value: 'all' },
      // roleOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Admin', value: 'admin' },
      //   { label: 'User', value: 'user' },
      //   { label: 'Staff', value: 'staff' }
      // ],

      // statusFilter: { label: 'Todos', value: 'all' },
      // statusOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Ativo', value: 'active' },
      //   { label: 'Inativo', value: 'deactivated' },
      //   { label: 'Bloqueado', value: 'blocked' }
      // ],

      // isVerifiedFilter: { label: 'Todos', value: 'all' },
      // isVerifiedOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Sim', value: 'yes' },
      //   { label: 'Não', value: 'no' }
      // ],

      // departmentFilter: { label: 'Todos', value: 'all' },
      // departmentOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Vendas', value: 'sales' },
      //   { label: 'Desenvolvimento', value: 'development' },
      //   { label: 'Gerência', value: 'management' }
      // ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado neste período.',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        autoHeight: true,
        wrapText: true
      },
      columnDefs: [
        {
          headerName: 'Ações',
          field: 'actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            printReceipt: this.printReceipt, // usado para passar parametros para dentro o componente renderizado no ag grid
            chargeback: this.chargeback, // usado para passar parametros para dentro o componente renderizado no ag grid
            editTransaction: this.editTransaction,
            deleteTransaction: this.deleteTransaction,
            destroyTransaction: this.destroyTransaction
          }
        },
        // {
        //   headerName: 'CÓDIGO',
        //   field: 'id',
        //   width: 140,
        //   floatingFilter:true,
        //   filter: 'agNumberColumnFilter',
        //   filterParams: {
        //     suppressAndOrCondition: true,
        //     filterOptions: ['startsWith'],
        //     defaultOption: 'startsWith',
        //     debounceMs: 800
        //   }
        //   // checkboxSelection: true,
        //   // headerCheckboxSelectionFilteredOnly: true,
        //   // headerCheckboxSelection: true
        // },
        {
          headerName: 'Data',
          field: 'created_at',
          width: 135,
          // floatingFilter:true,
          // filter: 'agDateColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['equals', 'inRange'],
          //   defaultOption: 'equals',
          //   debounceMs: 800,
          // comparator: (filterLocalDateAtMidnight, cellValue) => {
          //   const dateAsString = cellValue
          //   if (dateAsString === null) return -1
          //   const dateParts = dateAsString.split('/')
          //   const cellDate = new Date(
          //     Number(dateParts[2]),
          //     Number(dateParts[1]) - 1,
          //     Number(dateParts[0])
          //   )

          //   if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          //     return 0
          //   }

          //   if (cellDate < filterLocalDateAtMidnight) {
          //     return -1
          //   }

          //   if (cellDate > filterLocalDateAtMidnight) {
          //     return 1
          //   }
          // },
          //   browserDatePicker: true,
          //   minValidYear: 2000
          // },
          cellRendererFramework: 'CellRendererDateTime'
        },
        {
          headerName: 'Descrição',
          field: 'observation',
          minWidth: 400,
          cellClass: 'my-class',
          valueFormatter: params => {
            if (params.data) {
              const services = (params.data.transaction && params.data.transaction.servicePackages) ? params.data.transaction.servicePackages.map(e => {
                return e ? ` ${e.name || ''} ${e.category_cnh || ''}` : ''
              }).flat() : ''
              if (params.data.type !== 'CHARGEBACK' && params.data.transaction) {
                return (params.data.student ? `${params.data.student.name} | ` : '') + (params.data.transaction.observation || 'Nenhuma descrição.') + services
              } else if (params.data.type === 'CHARGEBACK') {
                return `ESTORNO ${(params.data.transaction.type === 'DEBIT' ? ' DE DÉBITO | ' : ' DE CRÉDITO | ')} ${(params.data.student ? `${params.data.student.name} | ` : '')} ${(params.data.transaction.observation || 'Nenhuma descrição.')} ${services}`
              }
            }
          },
          sortable: false

        // data.transaction && data.type !== 'CHARGEBACK' ?
        //     (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'NENHUMA DESCRIÇÃO.') :
        // data.type === 'CHARGEBACK' ?
        //     'ESTORNO ' + (data.transaction.type == 'DEBIT' ? ' DE DÉBITO | ': ' DE CRÉDITO | ' ) + (data.student ? data.student.name +' | ' : '') + (data.transaction.observation ? data.transaction.observation : 'Nenhuma descrição.') :
        //     ''


          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   numberParser: (text) => {
          //     return text === null ? null : text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
          //       (regex, a1, a2, a3, a4) => `${a1}.${a2}.${a3}-${a4}`)
          //   },
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 135,
          valueFormatter: params => {
            if (params.value) {
              if (params.value === 'TRANSFER') return 'Transferência'
              else if (params.value === 'CHARGEBACK') return 'Estorno'
              else if (params.value && params.data.type_payment) return params.data.type_payment.type_payment
            }
          },
          sortable: false
          // floatingFilter:true,
          // filter: 'agNumberColumnFilter',
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // },
        },
        {
          headerName: 'Entrada',
          field: 'value',
          width: 115,
          valueFormatter: params => {
            return params.value && params.data.direction === 'IN' ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Saída',
          field: 'value',
          width: 115,
          valueFormatter: params => {
            return params.value && params.data.direction === 'OUT' ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
          // floatingFilter:true,
          // filter: true,
          // filterParams: {
          //   suppressAndOrCondition: true,
          //   filterOptions: ['startsWith'],
          //   defaultOption: 'startsWith',
          //   debounceMs: 800
          // }
        },
        {
          headerName: 'Saldo',
          field: 'balanceLocal',
          width: 115,
          valueFormatter: params => {
            return params.data ? params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '--'
          },
          sortable: false
        },
        {
          headerName: 'Responsável',
          field: 'user',
          width: 200,
          valueFormatter: params => {
            return params.value ? params.value.name : ''
          },
          sortable: false
        }
      ],

      components: {
        CellRendererDateTime,
        CellRendererActions
      },

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: {
          firstPeriod: '',
          lastPeriod: '',
          responsible: ''
        },
        params: null,
        consultApi: true
      }
    }
  },
  watch: {
    // roleFilter (obj) {
    //   this.setColumnFilter('id', obj.value)
    // },
    // statusFilter (obj) {
    //   this.setColumnFilter('name', obj.value)
    // },
    // isVerifiedFilter (obj) {
    //   const val = obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
    //   this.setColumnFilter('name', val)
    // },
    // departmentFilter (obj) {
    //   this.setColumnFilter('cpf', obj.value)
    // }
  },
  computed: {
    user () {
      return this.$store.state.AppActiveUser
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    cashier () {
      return this.$store.state.myCashier.cashier
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    period () {
      if (this.payload.searchQuery.firstPeriod && this.payload.searchQuery.lastPeriod) {
        return `${moment(this.payload.searchQuery.firstPeriod).format('DD/MM/YYYY')} até ${moment(this.payload.searchQuery.lastPeriod).format('DD/MM/YYYY')}`
      }
      return `${moment().format('DD/MM/YYYY')} até ${moment().format('DD/MM/YYYY')}`
    },
    allData () {
      const data = this.$store.state.generalCash ? this.$store.state.generalCash.generalTransactions : null
      return JSON.parse(JSON.stringify(data))
    },
  },
  methods: {
    // APENAS PARA TESTES E USO FUTURO. EXEMPLO DE COMO REDIMENSIONAR LINHAS
    // i () {
    //   this.gridApi.forEachNode((rowNode) => {
    //     console.log(rowNode)
    //     rowNode.setRowHeight(100)
    //   })
    // },
    closeViewSidebar () {
      this.isSidebarActive = false
    },
    openSidebar () {
      this.isSidebarActive = true
    },

    async transferHandle () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_transfer_between_account')
      if (this.user.roles && this.user.roles.some && !this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }

      this.transfer_value = 0
      this.account_to = null
      this.account_from = null

      try {
        this.accountsToOptions = JSON.parse(JSON.stringify(this.accounts))
        this.accountsFromOptions = JSON.parse(JSON.stringify(this.accounts))
        if (this.cashier.status === 'OPEN') {
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/cashier/get/criteria`, { status:'OPEN', notMe: false })
          //INSIRO TODOS OS CAIXAS ABERTOS "PARA"
          for (const k in response.data) {
            this.accountsToOptions.push({ value: response.data[k], label: `CAIXA DE ${response.data[k].user.name}` })
          }

          //INSIRO TODOS OS CAIXAS ABERTOS "DE"
          for (const k in response.data) {
            this.accountsFromOptions.push({ value: response.data[k], label: `CAIXA DE ${response.data[k].user.name}` })
          }
        }

        this.popupTransfer = true
      } catch (error) { console.log(error) }
    },
    async transfer () {
      let message = false
      if (this.transfer_value <= 0) message = 'Defina o valor a ser transferido!'
      else if (!this.account_from || !this.account_to) message = 'Defina a conta origem e destino!'
      else if (_.isEqual(this.account_to, this.account_from)) message = 'Escolha origem e destino diferentes!'
      // else if (!_.has(this.account_from, 'opening_date') && !_.has(this.account_to, 'opening_date')) message = 'A origem ou o destino deve ser seu caixa!'
      else if (this.account_from.final_balance && (this.account_from.final_balance < this.transfer_value)) message = 'Não há saldo suficiente no caixa de origem!'
      // else if (_.has(this.account_from, 'balance') && (!_.isEqual(this.account_to, this.cashier))) message = 'Você não pode transferir valores de uma conta para um caixa que não seja o seu!'
      if (message) {
        this.$vs.notify({
          time: 6000,
          title: 'AVISO',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position:'top-center'
        })
      } else {
        try {
          this.$vs.loading()
          const response = await axios.post(`${process.env.VUE_APP_API_URL}/cashier/transfer`, { from: this.account_from, to: this.account_to, transfer_value: this.transfer_value })
          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Valor Transferido.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
          // this.$store.commit('myCashier/SET_CASHIER', response.data) //atualizo o cx para ter saldo e etc iguais no fron e back end
          this.refreshGrid()
          this.$vs.loading.close()
          this.popupTransfer = false
        } catch (error) {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        }
      }
    },

    getSearchResult (searchVal) {
      this.payload.searchQuery.responsible = searchVal
      // this.saldoAnterior = 0
      this.gridApi.setDatasource(this.dataSource)
    },
    // setColumnFilter (column, val) {
    //   const filter = this.gridApi.getFilterInstance(column)
    //   let modelObj = null

    //   if (val !== 'all') {
    //     modelObj = { type: 'equals', filter: val }
    //   }

    //   filter.setModel(modelObj)
    //   this.gridApi.onFilterChanged()
    // },
    // resetColFilters () {
    // Reset Grid Filter
    // this.gridApi.setFilterModel(null)
    // this.gridApi.onFilterChanged()

    // Reset Filter Options
    // this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'Todos', value: 'all' }

    // this.$refs.filterCard.removeRefreshAnimation()
    // },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
      // this.gridApi.setQuickFilter(val)
    },
    batchPayment () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_batch_edit')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.popupBatchPayment = true
    },
    credit () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_income')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.popupCredit = true
    },
    debit () {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_expenses')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.popupDebit = true
    },
    refreshGrid () {
      // this.saldoAnterior = 0
      this.gridApi.setDatasource(this.dataSource)
    },
    filterDate () {
      if (moment(this.payload.searchQuery.firstPeriod).year() > 1000 && moment(this.payload.searchQuery.lastPeriod).year() > 1000) {
        const difDays = moment(this.payload.searchQuery.lastPeriod).diff(moment(this.payload.searchQuery.firstPeriod), 'days')
        if (difDays < 0) {
          this.periodError = true
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período inicial deve ser anterior ao final.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        } else if (difDays > ((365 * 5) + 1)) {
          this.$vs.notify({
            time: 6000,
            title: 'AVISO',
            text: 'O período não pode ser maior que 5 anos.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }
        this.periodError = false
        // this.saldoAnterior = 0
        this.gridApi.setDatasource(this.dataSource)
      } else if (!this.payload.searchQuery.firstPeriod && !this.payload.searchQuery.lastPeriod) {
        this.periodError = false
        this.gridApi.setDatasource(this.dataSource)
      }
    },

    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params

          if (self.payload.consultApi) await self.getAllData()
          self.payload.consultApi = true //esta linha faz com que a próxima chamada sempre seja feita para a API

          let lastRow = -1
          if (self.allData.total <= params.endRow) {
            lastRow = self.allData.total
          }
          //CALCULA O SALDO
          if (self.currentPage === 1) self.saldoAnterior = 0
          self.allData.data.map(a => {
            if (a.direction === 'IN') {
              a.balanceLocal = self.saldoAnterior + a.value
              self.saldoAnterior = self.saldoAnterior + a.value
            } else if (a.direction === 'OUT') {
              a.balanceLocal = self.saldoAnterior - a.value
              self.saldoAnterior = self.saldoAnterior - a.value
            }
          })
          if (self.currentPage > 1) self.saldoAnterior = self.allData.data[self.allData.data.length - 1].balanceLocal
          self.totalIn = self.allData.totalIn
          self.totalOut = self.allData.totalOut
          self.totalInWithoutTransfer = self.allData.totalInWithoutTransfer
          self.totalOutWithoutTransfer = self.allData.totalOutWithoutTransfer
          self.totalReceivable = self.allData.totalReceivable
          self.totalPayable = self.allData.totalPayable
          params.successCallback(self.allData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllData () {
      this.gridApi.showLoadingOverlay()
      const data =  await this.$store.dispatch('generalCash/fetchFilter', this.payload).catch(err => { console.error(err) })
      this.gridApi.hideOverlay()
      if (!data.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return JSON.parse(JSON.stringify(data))
    },

    // BAIXA O ARQUIVO EXCEL OU WORD
    makeBlobDoc (blob, filename) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `${filename || 'Relatório'}.xlsx`
      link.click()
    },
    async printReport (type) {
      this.$vs.loading()
      this.showPdfTitle = 'Relatório de Caixa Geral'
      const resp = await this.$http.post(`${process.env.VUE_APP_API_URL}/cashFlow/report`, {
        initial_date: this.payload.searchQuery.firstPeriod || this.today,
        final_date: this.payload.searchQuery.lastPeriod || this.today,
        orderBy: 'expiration_date',
        format: type,
        payload: {
          title: 'Relatório de Caixa Geral'
        }
      }, { responseType: type === 'EXCEL' ? 'arraybuffer' : null })

      if (type === 'PDF') {
        this.srcPdf = `data:application/pdf;base64,${resp.data}`
        this.popupShowPdf = true
      } else if (type === 'EXCEL') {
        const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        this.makeBlobDoc(blob, this.showPdfTitle)
      }
      this.$nextTick(() => { this.$vs.loading.close() })
    },
    printReceipt (data, node) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_recibo')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      if (data.transaction && data.transaction.receipts.length) {
        //possui recibo, imprime 2ª via
        this.generateReceipt(data, node)
      } else if (data.transaction && !data.transaction.receipts.length) {
        //não possui recibo, devo perguntar e se for o caso cria recibo
        this.popupGenerateReceiptConfirm = true
        this.printReceiptData = data
        this.printReceiptNode = node
      }
    },
    generateReceipt (data, node) {
      this.$vs.loading()
      this.popupGenerateReceiptConfirm = false
      axios.get(`${process.env.VUE_APP_API_URL}/document/pdf/receipt/${data.transaction_id}`)
        .then(function (response) {
          this.showPdfTitle = 'Recibo'
          this.srcPdf = `data:application/pdf;base64,${response.data.pdf}`
          this.popupShowPdf = true

          /**
           * ALTERANDO GRID SEM REFRESH NAS LINHAS ABAIXO
           */
          data.transaction = response.data.transaction
          const rowNode = this.gridApi.getRowNode(node.id)
          rowNode.setData(data)
          // rowNode.setDataValue('type', 'CHARGEBACK')
          if (data.transaction && !data.transaction.receipts.length) this.$store.commit('generalCash/UPDATE', data)

          this.$vs.loading.close()
        }.bind(this))
        .catch(function () {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Desculpe, algo deu errado. Por favor avise o suporte.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        }.bind(this))
    },
    chargeback (data) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_chargeback')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.$refs.chargeBack.allowDelete = false
      this.$store.commit('generalCash/SET', data)
      this.popupChargeback = true
    },
    deleteTransaction (data) {
      // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
      const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_chargeback')
      if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('save'))) {
        this.$vs.notify({
          time: 5000,
          title: 'Aviso',
          text: 'Você não tem permissão para <br> EXECUTAR ESTA ação.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'center-top'
        })
        return
      }
      this.$store.commit('generalCash/SET', data)
      this.$refs.chargeBack.allowDelete = true
      this.popupChargeback = true
    },
    editTransaction (data) {
      this.localTransaction = data
      this.popupTransactionCredit = true
    },
    destroyTransaction (data) {
      this.tempTransaction = data.id
      this.popupTransaction = true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('allData')

    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
    this.debouncedFilterDate = _.debounce(this.filterDate, 600)

    if (!moduleGeneralCash.isRegistered) {
      this.$store.registerModule('generalCash', moduleGeneralCash)
      moduleGeneralCash.isRegistered = true
    }
    if (!moduleMyCashier.isRegistered) {
      this.$store.registerModule('myCashier', moduleMyCashier)
      moduleMyCashier.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      //  this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })

    // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
    const mod = this.user.permissions_user.find(a => a.module === 'spa_general_cashier')
    if (!this.user.roles.some(role => role.name === 'admin') && (!mod || !mod.pivot.permissions.includes('read'))) {
      this.showGeneralCash = false
    }

    // SE NÃO FOR ADMIN E NÃO TIVER PERMISSÃO - CONTROLE DE ACESSO
    const showBalanceTotal = this.user.permissions_user.find(a => a.module === 'spa_general_cashier_account_balance_total')
    if (!this.user.roles.some(role => role.name === 'admin') && (!showBalanceTotal || !showBalanceTotal.pivot.permissions.includes('read'))) {
      this.showBalanceTotal = false
    }

  }
}
</script>

<style lang="scss">
  .popup40 .vs-popup {
    width: 40% !important;
  }

#page-general-cash {
  .full-vs-sidebar .vs-sidebar {
      max-width: calc(100% - 10px);
  }
  .general-cash-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .vx-card .vx-card__collapsible-content .vx-card__body {
    padding: 1rem !important;
  }
  .ag-floating-filter-button {
    margin-left: 0px !important;
  }
  .my-class {
    line-height: 25px;
  }
  .cell-wrap-text {
    white-space: normal !important;
  }
  .ag-cell-wrap-text {
    word-break: break-word !important;
  }
  .ag-theme-material .ag-cell {
    padding-left: 10px; // 23
    padding-right: 10px; // 23
  }
  .ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell {
    padding-left: 12px;
    padding-right: 12px;
  }
  // .ag-row-position-absolute {
  //   position: inherit;
  // }
  // .ag-row {
  //   margin-bottom: -40px;
  // }
  .row-danger {
    color: red;
  }
  .rowClass {
    font-size: 12px;
  }
  .popup80 .vs-popup {
    width: 80% !important;
  }
  .noOverflow .vs-popup--content {
    overflow: hidden;
  }
  .m-icon .material-icons {
    font-size: 19px !important;
  }
}
</style>
